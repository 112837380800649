<template>
  <NavPanel />
  <div class="base-page">
    <StateText />
    <h2>Cell state scoring and t-SNE of premalignant epithelia</h2>
    <p>Cell state scoring on epithelial cells from premalignant lesions across organs, and their t-SNE-based
      dimensionality reduction.</p>
    <div class="parent-container">
      <div class="child-container">
        <div class="middle-select-wrapper">
          <div class="select-label">Select sample attribute</div>
          <select v-model="selectedSignatureAttribute" @change="fetchSignatureScatter" class="dropdown-select">
            <option disabled value="">Select Attribute</option>
            <option v-for="signatureAttribute in signatureAttributes" :key="signatureAttribute">
              {{ signatureAttribute }}</option>
          </select>
        </div>
        <div ref="signatureScatter" class="chart"></div>
        <div ref="signatureScatterLegendContainer" class="legend-container">
        </div>
      </div>
      <div class="child-container">
        <div class="middle-select-wrapper">
          <div class="select-label">Select cell state module</div>
          <select v-model="selectedModuleIndex" @change="fetchSignatureScore" v-if="true" class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="moduleIndex in 19" :key="moduleIndex">{{ moduleIndex }}</option>
          </select>
        </div>
        <div ref="scoreScatter" class="chart"></div>
      </div>
    </div>
    <div class="parent-container">
      <div class="child-container">
        <div class="title">Cell State Scoring-Derived Dimensionality Reduction</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import seedrandom from 'seedrandom';
import NavPanel from "@/components/NavPanel.vue";
import StateText from '@/components/StateText.vue';
import commonMixin from '@/mixins/commonMixin';
import 'vue3-select/dist/vue3-select.css';

export default {
  components: { NavPanel, StateText },
  mixins: [commonMixin],
  data() {
    return {
      signatureAttributes: ['presig_cell_type', 'presig_dataset_id', 'presig_lesion_orig', 'presig_lesion_revised', 'presig_cancer_source'],
      selectedSignatureAttribute: "",
      selectedModuleIndex: 1,
      signatureScore: [],
      signatureData: [],
    };
  },
  mounted() {
    this.selectedSignatureAttribute = this.signatureAttributes[0];
    this.fetchSignatureScatter();
  },
  methods: {
    async fetchSignatureScatter() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/signaturescatter`, {
          params: {
            signatureAttribute: this.selectedSignatureAttribute,
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const headers = rows[0].split('\t');
        this.signatureData = rows.slice(1).map(row => {
          const values = row.split('\t');
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
        });
        this.fetchSignatureScore();
        this.renderSignatureScatter(this.$refs.signatureScatter);
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async fetchSignatureScore() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/signaturescore`, {
          params: {
            moduleIndex: this.selectedModuleIndex
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        this.signatureScore = JSON.parse(decompressedData);
        this.maxScore = Math.ceil(...this.signatureScore);
        this.minScore = Math.floor(...this.signatureScore);
        this.renderScoreScatter(this.$refs.scoreScatter);
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    renderSignatureScatter(chartObj) {
      const rng = seedrandom('fixed-seed');
      let data = this.signatureData.slice();
      if (data.length > 5000) {
        for (let i = data.length - 1; i > 0; i--) {
          const j = Math.floor(rng() * (i + 1));
          [data[i], data[j]] = [data[j], data[i]];
        }
        data = data.slice(0, 5000);
      }
      this.signatureScatter = echarts.init(chartObj);
      const categories = [...new Set(data.map(item => item['colortype']))];
      const colors = Array.from({ length: categories.length }, (_, i) =>
        echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
      );
      const colorMap = categories.reduce((acc, category, index) => {
        acc[category] = colors[index];
        return acc;
      }, {});
      const containerWidth = chartObj.clientWidth;
      const containerHeight = chartObj.clientHeight;
      const size = Math.min(containerWidth, containerHeight) - 100;
      const left = (containerWidth - size) / 2;

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.marker} ${params.data.name}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
          }
        },
        grid: {
          left: `${left}px`,
          right: `${left}px`,
          bottom: '10%',
          containLabel: true,
          width: size,
          height: size,
        },
        xAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        series: [
          {
            name: 'Scatter',
            type: 'scatter',
            symbolSize: 2,
            data: data.map(item => ({
              value: [
                parseFloat(item['TSNE_1']),
                parseFloat(item['TSNE_2'])
              ],
              name: item['colortype'],
              itemStyle: {
                color: colorMap[item['colortype']]
              }
            }))
          }
        ]
      };

      this.signatureScatter.setOption(option);
      this.createLegend(colorMap);
    },
    createLegend(colorMap) {
      const legendContainer = this.$refs.signatureScatterLegendContainer;
      legendContainer.innerHTML = '';

      Object.keys(colorMap).forEach(category => {
        const legendItem = document.createElement('span');
        legendItem.style.display = 'flex';
        legendItem.style.alignItems = 'center';
        legendItem.style.marginBottom = '5px';
        legendItem.style.marginRight = '15px';

        const colorBox = document.createElement('span');
        colorBox.style.width = '25px';
        colorBox.style.height = '14.4px';
        colorBox.style.backgroundColor = colorMap[category];
        colorBox.style.marginRight = '10px';
        colorBox.style.borderRadius = '3px';

        const labelText = document.createElement('span');
        labelText.textContent = category;
        labelText.style.fontSize = '12px';
        labelText.style.fontFamily = 'Helvetica, sans-serif';
        labelText.style.fontWeight = 'semibold';

        legendItem.appendChild(colorBox);
        legendItem.appendChild(labelText);
        legendContainer.appendChild(legendItem);
      });
    },
    async renderScoreScatter(chartObj) {
      if (!chartObj) return;

      const rng = seedrandom('fixed-seed');
      let data = this.signatureData.slice();
      data.forEach((item, index) => {
        item['score'] = this.signatureScore[index];
      });
      if (data.length > 5000) {
        for (let i = data.length - 1; i > 0; i--) {
          const j = Math.floor(rng() * (i + 1));
          [data[i], data[j]] = [data[j], data[i]];
        }
        data = data.slice(0, 5000);
      }
      if (this.scoreScatter) {
        this.scoreScatter.dispose();
      }
      this.scoreScatter = echarts.init(chartObj);

      const containerWidth = chartObj.clientWidth;
      const containerHeight = chartObj.clientHeight;
      const size = Math.min(containerWidth, containerHeight) - 100;
      const left = (containerWidth - size) / 2;

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.data.value[2].toFixed(4)}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
          }
        },
        grid: {
          left: `${left}px`,
          right: `${left}px`,
          bottom: '10%',
          containLabel: true,
          width: size,
          height: size,
        },
        xAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        visualMap: {
          show: true,
          type: 'continuous',
          calculable: true,
          min: this.minScore,
          max: this.maxScore,
          color: ['red', 'yellow', 'green', 'blue'],
          textStyle: {
            color: '#333'
          }
        },
        series: [
          {
            name: 'Gene Expression',
            type: 'scatter',
            data: data.map(item => ({
              value: [
                parseFloat(item['TSNE_1']),
                parseFloat(item['TSNE_2']),
                parseFloat(item['score'])
              ],
              symbolSize: 3
            })),
          }
        ]
      };
      this.scoreScatter.setOption(option);
    }
  }
};
</script>