<template>
  <h1>
    <p>Cell Abundance Analysis</p>
  </h1>
  <p>PreAtlas provides users with the ability to explore changes in expert-annotated cell type compositions within
    curated premalignant scRNA-seq datasets, as well as the specific markers for each cell type (derived from
    FindAllMarkers in Seurat). Additionally, it allows users to view the distribution of basic clinical
    information for the samples.</p>
</template>

<script>
export default {
  name: "AbundanceText"
}
</script>

<style>
.base-page {
  width: 80%;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding: 20px;
  box-sizing: border-box;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-align: left;
    margin: 5px;
    padding: 0;
  }

  h1>p {
    border-bottom: 2px solid #BFBFBF;
    display: inline-block
  }

  p~h2::before {
    content: ' ';
    display: block;
    width: 100%;
    border-top: 2px solid #BFBFBF;
    margin: 10px 0;
  }
}

.base-page>p {
  font-style: italic;
  margin-bottom: 20px;
}

.left-select-wrapper {
  position: absolute;
  left: 10px;
}

.middle-select-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.select-label {
  position: sticky;
  top: -20px;
  left: 0;
  font-size: 14px;
  color: #333;
  z-index: 10;
  white-space: nowrap;
}

.dropdown-select {
  width: 180px;
  z-index: 100;
}

.parent-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
  gap: 20px;
}

.child-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: hidden;
  clip-path: inset(0 -10px 0 0);
}

.child-container:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: #BFBFBF;
  transform: translateX(-50%);
}

.chart {
  width: 100%;
  height: 500px;
  margin: 30px 0;
}

.legend-container {
  position: absolute;
  bottom: 60px;
  right: 0;
  left: 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  margin-left: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin: 20px;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination button.active {
  font-weight: bold;
  text-decoration: underline;
}

.header-content {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.sort-icons {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.triangle-up,
.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: 2px 0;
}

.triangle-up {
  border-bottom: 8px solid #ccc;
}

.triangle-down {
  border-top: 8px solid #ccc;
}

.triangle-up.active {
  border-bottom-color: #000;
}

.triangle-down.active {
  border-top-color: #000;
}

.inactive {
  border-bottom-color: #ccc;
  border-top-color: #ccc;
}
</style>