<template>
  <div class="contact-page">
    <div class="contact-text">
      <p>
        <strong>Corresponding Author:</strong> <br>
        Shao Li, MD, PhD, Prof.
      </p>
      <p>
        MOE Key Laboratory of Bioinformatics, TCM-X Centre/Bioinformatics Division, BNRIST<br>
        Tsinghua University<br>
        Beijing, 100084<br>
        P. R. China
      </p>
      <p>
        Tel: <a href="tel:+861062797035">+86 10 62797035</a><br>
        Fax: <a href="fax:+861062786911">+86 10 62786911</a><br>
        E-mail: <a href="mailto:shaoli@mail.tsinghua.edu.cn">shaoli@mail.tsinghua.edu.cn</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.contact-page {
  display: flex;
  justify-content: center;
  padding: 20px;

  .contact-text {
    text-align: left;
    font-size: 36px;
    line-height: 1.6;
    max-width: 1200px;

    a {
      color: blue;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
</style>