<template>
  <h1>
    <p>Gene Expression Profile</p>
  </h1>
  <p>PreAtlas allows users to investigate the expression levels of specific genes of interest across premalignant
    lesion-enrolled scRNA-seq, bulk transcriptomic, and spatial transcriptomic datasets.</p>
</template>

<script>
export default {
  name: "ExpressionText"
}
</script>

<style>
select,
.vue-select {
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.vue-select input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.vue-select .vue-select-dropdown {
  max-height: 200px;
  overflow-y: auto;
}
</style>