<template>
  <NavPanel />
  <div class="base-page">
    <StateText />
    <h2>Cell state detail</h2>
    <p>The NMF factor compositions of each cell state module and their corresponding signature genes.</p>
    <div class="parent-container">
      <div class="child-container">
        <img src="../assets/images/sankey.svg" alt="Sankey Graph">
        <div class="title">Contribution of Each Module from Lesion-Derived Factors</div>
      </div>
      <div class="child-container">
        <div class="middle-select-wrapper">
          <div class="select-label">Select cell state module</div>
          <select v-model="selectedModuleIndex" @change="fetchModuleData" v-if="true" class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="moduleIndex in 19" :key="moduleIndex">{{ moduleIndex }}</option>
          </select>
        </div>
        <div class="title">Cell State Module-Derived Signature Genes</div>
        <div class="scrollable-table-wrapper">
          <table v-if="moduleTable.headers.length">
            <thead>
              <tr>
                <th v-for="header in moduleTable.headers" :key="header">
                  <span class="header-content">
                    {{ header }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in paginatedRows" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" :class="{ highlight: cell.highlight }">
                  {{ cell.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="moduleTable.headers.length">
          <button @click="goToPage(1)" :disabled="currentPage === 1">First</button>
          <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>

          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
            :class="{ active: currentPage === page }">
            {{ page }}
          </button>

          <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
          <button @click="goToPage(totalPages)" :disabled="currentPage === totalPages">Last({{ totalPages }})</button>
          <input type="number" v-model.number="inputPage" @keyup.enter="goToInputPage" min="1" :max="totalPages" />
          <button @click="goToInputPage">Go</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import * as echarts from 'echarts';
// import pako from 'pako';
// import seedrandom from 'seedrandom';
import NavPanel from "@/components/NavPanel.vue";
import StateText from '@/components/StateText.vue';
import commonMixin from '@/mixins/commonMixin';
import 'vue3-select/dist/vue3-select.css';

export default {
  components: { NavPanel, StateText },
  mixins: [commonMixin],
  data() {
    return {
      selectedModuleIndex: 1,
      moduleTable: {
        headers: [],
        rows: []
      },
      currentPage: 1,
      rowsPerPage: 15,
      nearbyPagesCount: 2,
      sortColumn: 0,
      sortDirection: 'asc',
      inputPage: 1,
    };
  },
  mounted() {
    this.fetchSCDatasets();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.sortedRows(this.moduleTable.rows).length / this.rowsPerPage);
    },
    paginatedRows() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.sortedRows(this.moduleTable.rows).slice(start, end);
    },
    visiblePages() {
      let start = Math.max(1, this.currentPage - this.nearbyPagesCount);
      let end = Math.min(this.totalPages, this.currentPage + this.nearbyPagesCount);

      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    }
  },
  methods: {
    async fetchSCDatasets() {
      try {
        this.selectedSCDataset = 'BRCA'; // prefetch BRCA
        this.fetchModuleData();
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
        this.scDatasets = response.data.scDatasets;
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    async fetchModuleData() {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/moduletable`, {
        params: {
          moduleIndex: this.selectedModuleIndex
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const tsvData = response.data.tsv;
      const hotspotsData = JSON.parse(response.data.hotspots);
      const rows = tsvData.trim().split('\n');
      // substitute _dot_ with .
      const headers = rows[0].split('\t').map(header => header.replace(/_dot_/g, '.'));
      const formattedRows = rows.slice(1).map(row => {
        const cells = row.split('\t');
        return cells.map(cell => ({
          value: cell,
          highlight: hotspotsData.includes(cell)
        }));
      });

      // Update moduleTable with headers and formatted rows
      this.moduleTable = {
        headers,
        rows: formattedRows
      };
    }
  }
};
</script>

<style>
.scrollable-table-wrapper {
  overflow-x: auto;
  width: 100%;
  padding-bottom: 10px;

  table {
    width: max-content;
  }
}

.highlight {
  color: red;
}
</style>``