<template>
  <div class="base-page">
    <div class="container-v">
      <div class="discription-logo-text">
        <img src="../assets/images/preatlas-logo.jpg" alt="PreAtlas logo" class="logo-image">
        <div class="discription-text">
          The PreAtlas database provides comprehensive resources and an interactive
          analysis platform for exploring the vast amount of bulk and single-cell
          transcriptomic data, as well as curated knowledges specially pertaining to
          diverse premalignant disease for 19 cancer types across 15 body sites.
        </div>
      </div>
    </div>
    <div class="container-p">
      <div class="container-vl">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Recent Events</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Add data download function<strong>2024-07-21</strong></td>
              </tr>
              <tr>
                <td>Add four analyze modules<strong>2024-04-21</strong></td>
              </tr>
              <tr>
                <td>Solve network problems<strong>2024-02-01</strong></td>
              </tr>
              <tr>
                <td>Complete data upload<strong>2024-01-10</strong></td>
              </tr>
              <tr>
                <td>Create the service site<strong>2023-10-10</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="citation">Citation:</div>
      </div>
      <div class="organ-map-container">
        <img src="../assets/images/bodymap_1.png" alt="Human Body" class="human-body"> <img
          src="../assets/images/lung-cancer.png" alt="Lung"
          title="Lung-LUAD&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon left-icon icon-1">
        <img src="../assets/images/breast-cancer.png" alt="Breast"
          title="Breast-BRCA&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon left-icon icon-2">
        <img src="../assets/images/cervical-cancer.png" alt="Cervix"
          title="Cervix-CESC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon left-icon icon-3">
        <img src="../assets/images/skin-cancer.png" alt="Skin"
          title="Skin-NMSC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon left-icon icon-4">
        <img src="../assets/images/liver-cancer.png" alt="Liver"
          title="Liver-HCC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon left-icon icon-5">

        <img src="../assets/images/throat-cancer.png" alt="Head and Neck"
          title="Head -HNSC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon right-icon icon-1">
        <img src="../assets/images/stomach-cancer.png" alt="Stomach"
          title="Stomach-STAD&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon right-icon icon-2">
        <img src="../assets/images/bowel-cancer.png" alt="Colorectum"
          title="Colorectum-COAD&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283&#10;&#10;Colorectum-CRC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon right-icon icon-3">
        <img src="../assets/images/esophagus.png" alt="Esophagus"
          title="Esophagus-ESCC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon right-icon icon-4">
        <!-- <img src="../assets/images/ovary-cancer.png" alt="Ovary" title="Ovary-BRCA&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283" class="organ-icon right-icon icon-4"> -->
        <img src="../assets/images/pancreatic-cancer.png" alt="Pancreas"
          title="Pancreas-PDAC&#10;single cell RNA-seq datasets: 31776&#10;bulk transcriptomic datasets: 19283&#10;spatial transcriptomic datasets: 19283"
          class="organ-icon right-icon icon-5">
      </div>
      <div class="container-vr">
        <img src="../assets/images/data_overview.png" alt="data image" class="data_image">
        <div class="database">Related databases</div>
        <div class="image-grid">
          <a href="https://www.ncbi.nlm.nih.gov/geo/" target="_blank">
            <img src="../assets/images/GEO.gif" alt="GEO"></a>
          <a href="https://www.ebi.ac.uk/biostudies/arrayexpress" target="_blank">
            <img src="../assets/images/ArrayExpress.png" alt="ArrayExpress"></a>
          <a href="https://ngdc.cncb.ac.cn/gsa/" target="_blank">
            <img src="../assets/images/GSA.png" alt="GSA"></a>
          <a href="https://www.ebi.ac.uk/ols4/index" target="_blank">
            <img src="../assets/images/OLS.png" alt="OLS"></a>
          <a href="https://hpo.jax.org/" target="_blank">
            <img src="../assets/images/hpo-logo-black.png" alt="HPO"></a>
          <a href="https://www.genecards.org/" target="_blank">
            <img src="../assets/images/GeneCard.webp" alt="GeneCard"></a>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-left">
          <p>&copy; 2024 PreAtlas, All Rights Reserved.</p>
        </div>
        <div class="footer-right">
          Credits: <a href="https://www.flaticon.com/free-icons/cancer" title="cancer icons">Cancer icons created by
            Freepik - Flaticon</a> |
          <a href="https://www.flaticon.com/free-icons/pancreatic-cancer" title="pancreatic cancer icons">Pancreatic
            cancer icons created by Freepik - Flaticon</a> |
          <a href="https://www.flaticon.com/free-icons/gynecology" title="gynecology icons">Gynecology icons created by
            Freepik - Flaticon</a> |
          <a href="https://www.flaticon.com/free-icons/esophagus" title="esophagus icons">Esophagus icons created by
            Freepik - Flaticon</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  data() {
    return {
      searchQuery: ''
    };
  }
};
</script>

<style scoped lang="scss">
$icon-count: 6;
$top-gap: 98px;
$base-offset: -150px;
$multiplier: 30px;

.base-page {
  .container-v {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .discription-logo-text {
    display: flex;
    align-items: center;
  }

  .logo-image {
    width: 70px;
    height: auto;
    margin-right: 15px;
  }

  .discription-text {
    text-align: justify;
    color: black;
    background-color: #D8D9A0;
    padding: 15px;
    border-radius: 10px;
    width: 1100px;
    height: 70px;
    margin: 10px 5px;
    word-spacing: 2px;
    align-self: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .quick-search {
    text-align: center;
    color: black;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
  }

  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;

    input[type="text"] {
      width: 500px;
      padding: 5px;
      font-size: 15px;
    }

    .submit {
      font-size: 15px;
      padding: 10px 15px;
    }

    .mr-btn {
      margin-top: 8px;
    }
  }

  .container-p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .container-vl {
    display: flex;
    flex-direction: column;
  }

  .container-vr {
    flex-direction: column;
    align-content: flex-start;
  }

  .table-container {
    width: 400px;
    border-collapse: inherit;
    margin-right: auto;

    th {
      font-weight: 400;
      font-size: 30px;
    }

    td {
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      padding-left: 20px;
      position: relative;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: blue;
        border-radius: 50%;
        position: absolute;
        left: 5px;
      }

      strong {
        text-align: right;
        margin-left: auto;
        font-weight: bold;
      }
    }
  }

  .citation {
    text-align: justify;
    color: black;
    background-color: #F2F2F2;
    padding: 15px;
    border-radius: 10px;
    width: 400px;
    height: 25px;
    margin: 100px 0px;
    align-self: flex-start;
    display: flex;
  }

  .data_image {
    margin: 10px, 10px;
  }

  .database {
    padding: 20px;
    align-self: flex-start;
    font-size: 20px;
    font-weight: bold;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3 columns
    grid-template-rows: repeat(2, 1fr); // 2 rows
    gap: 10px;
    width: 100%;
    max-width: 600px;
  }

  .image-grid img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .organ-map-container {
    position: relative;
    width: 300px;
    margin: 50px auto;

    .human-body {
      width: 100%;
      height: auto;
    }

    .organ-icon {
      position: absolute;
      width: 50px;
      height: 50px;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    @for $i from 1 through $icon-count {
      .left-icon.icon-#{$i} {
        top: ($i - 1) * $top-gap;
        left: $base-offset + abs($i - 3) * $multiplier;
      }

      .right-icon.icon-#{$i} {
        top: ($i - 1) * $top-gap;
        right: $base-offset + abs($i - 3) * $multiplier;
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .footer-right {
    font-size: 10px;
    align-items: center;
    display: flex;
  }
}
</style>