<template>
  <NavPanel />
  <div class="base-page">
    <StateText />
    <h2>Modules in NMF signature network</h2>
    <p>A similarity network among the factors is constructed using Gene Ontology (GO) semantic similarity as the metric,
      highlighting the modular properties of the NMF-based hierarchical clustering.</p>
    <div parent-container>
      <div class="child-container">
        <div ref="network" class="chart"></div>
        <div class="title">GO-Derived Similarity Network of Cell State Modules</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import NavPanel from "@/components/NavPanel.vue";
import StateText from '@/components/StateText.vue';
import commonMixin from '@/mixins/commonMixin';
import 'vue3-select/dist/vue3-select.css';

export default {
  components: { NavPanel, StateText },
  mixins: [commonMixin],
  data() {
    return {
      networkData: null,
      chart: null
    };
  },
  mounted() {
    this.fetchNetwork();
  },
  beforeUnmount() {
    if (this.chart) {
      window.removeEventListener('resize', this.handleResize);
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    async fetchNetwork() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/networks`);
        this.networkData = response.data.networks;
        this.renderNetwork();
      } catch (error) {
        console.error('Error fetching network data:', error);
      }
    },
    renderNetwork() {
      if (!this.networkData) return;

      const nodes = [];
      const links = [];
      const categories = [];
      const nodeToCategory = {};

      const assignCategory = (node, category) => {
        if (nodeToCategory[node] !== undefined) return;
        nodeToCategory[node] = category;
        if (Array.isArray(this.networkData[node])) {
          this.networkData[node].forEach(target => assignCategory(target, category));
        }
      };

      let categoryCount = 0;
      Object.keys(this.networkData).forEach(node => {
        if (nodeToCategory[node] === undefined) {
          assignCategory(node, categoryCount);
          categories.push({ name: `Component ${categoryCount}` });
          categoryCount++;
        }
      });

      Object.keys(this.networkData).forEach((node, index) => {
        const angle = (index / Object.keys(this.networkData).length) * Math.PI * 2;
        const radius = 300;
        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;

        nodes.push({
          id: node,
          name: node,
          category: nodeToCategory[node],
          x: x,
          y: y,
        });

        if (Array.isArray(this.networkData[node])) {
          this.networkData[node].forEach(target => {
            links.push({
              source: node,
              target: target,
            });
          });
        }
      });

      const chart = echarts.init(this.$refs.network);
      const option = {
        animation: false,
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'node') {
              return `Node: ${params.data.name}`;
            } else if (params.dataType === 'edge') {
              return `Edge: ${params.data.source} - ${params.data.target}`;
            }
          }
        },
        series: [
          {
            name: 'Network',
            type: 'graph',
            layout: 'force',
            data: nodes,
            links: links,
            categories: categories,
            roam: true,
            label: {
              show: true,
              position: 'right',
              fontWeight: 'bold',
            },
            force: {
              initLayout: 'none',
              repulsion: 200,
              gravity: 0.2,
              edgeLength: [50, 100],
              layoutAnimation: false,
            },
          }
        ],
      };

      chart.setOption(option);

      const floatingLabels = [
        { text: 'Cycle', position: [-300, -50] },
        { text: 'Stress', position: [-100, 400] },
        { text: 'Secreted', position: [-300, 180] },
        { text: 'Proteostatis', position: [180, -290] },
        { text: 'Interferon', position: [180, -150] },
        { text: 'Oxphos', position: [-180, -290] },
        { text: 'Signalling', position: [400, 180] },
        { text: 'Squamous', position: [-250, 120] },
        { text: 'pMET(PreSig)', position: [80, 300] },
        { text: 'Mesenchymal', position: [290, -120] },
        { text: 'Glandular', position: [0, 20] },
        { text: 'Glandular', position: [400, -40] },
      ];

      floatingLabels.forEach(label => {
        const div = document.createElement('div');
        div.innerText = label.text;
        div.style.position = 'absolute';
        div.style.padding = '4px 8px';
        div.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
        div.style.color = 'white';
        div.style.borderRadius = '4px';
        div.style.fontSize = '20px';
        div.style.transform = 'translate(-50%, -50%)';
        this.$refs.network.appendChild(div);
        label.element = div;
      });

      const updateLabelPositions = () => {
        if (!this.$refs.network) return;
        const containerWidth = this.$refs.network.clientWidth;
        const containerHeight = this.$refs.network.clientHeight;

        floatingLabels.forEach(label => {
          const pixelPos = chart.convertToPixel({ seriesIndex: 0 }, label.position);

          if (pixelPos) {
            const [x, y] = pixelPos;
            if (x >= 0 && x <= containerWidth && y >= 0 && y <= containerHeight) {
              label.element.style.left = `${x}px`;
              label.element.style.top = `${y}px`;
              label.element.style.display = 'block';
            } else {
              label.element.style.display = 'none';
            }
          }
        });
      };
      updateLabelPositions();

      chart.on('rendered', updateLabelPositions);
      chart.on('georoam', updateLabelPositions);

      window.addEventListener('resize', () => {
        chart.resize();
        updateLabelPositions();
      });
    }
  }
};
</script>