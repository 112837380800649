import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '@/views/Home.vue';
import DocPage from '@/views/DocPage.vue';
import ContactPage from '@/views/Contact.vue';
import CellTypeUMAP from '@/views/CellTypeUMAP.vue';
import CellTypeAbundance from '@/views/CellTypeAbundance.vue';
import MarkerProfiles from '@/views/MarkerProfiles.vue';
import SignatureNetwork from '@/views/SignatureNetwork.vue';
import CellStateDetail from '@/views/CellStateDetail.vue';
import CellStateScoring from '@/views/CellStateScoring.vue';
import ClinicalApplications from '@/views/ClinicalApplications.vue';
import scExpression from '@/views/scExpression.vue';
import BulkExpression from '@/views/BulkExpression.vue';
import stData from '@/views/stData.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/doc',
    name: 'DocPage',
    component: DocPage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/analyze/celltypeumap',
    name: 'CellTypeUMAP',
    component: CellTypeUMAP
  },
  {
    path: '/analyze/celltypeabundance',
    name: 'CellTypeAbundance',
    component: CellTypeAbundance
  },
  {
    path: '/analyze/markerprofiles',
    name: 'MarkerProfiles',
    component: MarkerProfiles
  },
  {
    path: '/analyze/signaturenetwork',
    name: 'SignatureNetwork',
    component: SignatureNetwork
  },
  {
    path: '/analyze/cellstatedetail',
    name: 'CellStateDetail',
    component: CellStateDetail
  },
  {
    path: '/analyze/cellstatescoring',
    name: 'CellStateScoring',
    component: CellStateScoring
  },
  {
    path: '/analyze/clinicalapplications',
    name: 'ClinicalApplications',
    component: ClinicalApplications
  },
  {
    path: '/analyze/scexpression',
    name: 'scExpression',
    component: scExpression
  },
  {
    path: '/analyze/bulkexpression',
    name: 'BulkExpression',
    component: BulkExpression
  },
  {
    path: '/analyze/stdata',
    name: 'stData',
    component: stData
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router