<template>
  <NavPanel />
  <div class="base-page">
    <ExpressionText />
    <h2>Bulk transcriptomic expression profile</h2>
    <p>Gene expression across bulk transcriptomic datasets, which include curated longitudinal, cross-sectional, and
      TCGA data.</p>
    <div class="parent-container">
      <div class="child-container">

      </div>

      <div class="child-container">
        <div class="dropdown-container">
          <select v-model="selectedBulkDataset" @change="fetchBulkFiles" class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="bulkDataset in bulkDatasets" :key="bulkDataset">{{ bulkDataset }}</option>
          </select>
          <v-select v-model="selectedBulkFile" :options="filteredBulkFiles" @search="filterBulkFiles"
            @change="fetchBulkExpressions" placeholder="Type to search for genes" :reduce="file => file"
            :input-debounce="300" class="dropdown-select" :disabled="!selectedBulkDataset" />
        </div>
        <div ref="bulkBoxplot" style="width: 100%; height: 600px; margin-top: 20px;"></div>
        <div class="title">Bulk data-based distribution</div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import * as echarts from 'echarts';
import vSelect from 'vue3-select';
import pako from 'pako';
import NavPanel from "@/components/NavPanel.vue";
import ExpressionText from "@/components/ExpressionText.vue";
import commonMixin from '@/mixins/commonMixin';
import 'vue3-select/dist/vue3-select.css';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: { vSelect, NavPanel, ExpressionText },
  mixins: [commonMixin],
  data() {
    return {
      bulkDatasets: [],
      bulkFiles: [],
      filteredBulkFiles: [],
      selectedBulkDataset: '',
      selectedBulkFile: '',
    };
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    this.disposeCharts();
  },
  methods: {
    async initialize() {
        this.selectedBulkDataset = 'GSE109743',
        this.selectedBulkFile = 'A1BG-AS1',
        await Promise.all([
          this.fetchBulkExpressions(),
          this.fetchBulkDatasets(),
        ]);
    },
    async fetchBulkDatasets() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
          params: {
            type: 'bulk'
          }
        });
        this.bulkDatasets = response.data.bulkDatasets;
        if (!this.bulkDatasets.includes(this.selectedBulkDataset)) {
          this.selectedBulkDataset = this.bulkDatasets[0];
        }
        this.fetchBulkFiles();
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    filterBulkFiles(search) {
      if (search.length < 2) {
        this.filteredBulkFiles = [];
      } else {
        const searchLower = search.toLowerCase();
        this.filteredBulkFiles = this.bulkFiles.filter(file =>
          file.toLowerCase().includes(searchLower)
        );
      }
    },
    async fetchBulkFiles() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkfiles`, {
          params: {
            type: 'bulk',
            bulkDataset: this.selectedBulkDataset
          }
        });
        this.bulkFiles = response.data.bulkFiles;
        this.filteredBulkFiles = [];
        if (!this.bulkFiles.includes(this.selectedBulkFile)) {
          this.selectedBulkFile = this.bulkFiles[0];
        }
        this.fetchBulkExpressions();
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    },
    async fetchBulkExpressions() {
      try {
        const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
          params: {
            type: 'bulk',
            bulkDataset: this.selectedBulkDataset,
            bulkFile: this.selectedBulkFile
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const dataByCategory = {};

        rows.forEach(row => {
          const [value, category] = row.split('\t');
          if (!dataByCategory[category]) {
            dataByCategory[category] = [];
          }
          dataByCategory[category].push(parseFloat(value));
        });

        const chart = echarts.init(this.$refs.bulkBoxplot);
        this.renderBulkBoxplot(dataByCategory, chart);
      } catch (error) {
        console.error('Error fetching cancer genes:', error);
      }
    },
    async renderBulkBoxplot(dataByCategory, chart) {
      const categories = Object.keys(dataByCategory);

      const boxData = [];
      const tooltipData = [];
      const outliersData = [];

      const boxColors = ['#1f77b4', '#2ca02c', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#ff7f0e'];

      categories.forEach((category) => {
        const values = dataByCategory[category];
        values.sort((a, b) => a - b);
        const rawMin = values[0];
        const rawMax = values[values.length - 1];
        const q1 = values[Math.floor(values.length / 4)];
        const median = values[Math.floor(values.length / 2)];
        const q3 = values[Math.floor(values.length * 3 / 4)];
        const iqr = q3 - q1;
        const lowerFence = q1 - 1.5 * iqr;
        const upperFence = q3 + 1.5 * iqr;

        const actualMin = values.find(v => v >= lowerFence) || rawMin;
        const actualMax = values.reverse().find(v => v <= upperFence) || rawMax;

        // Add box plot data
        boxData.push([actualMin, q1, median, q3, actualMax]);

        // Extended data for tooltip
        tooltipData.push([
          category,     // category name
          actualMin,    // actual minimum
          q1,           // first quartile
          median,       // median
          q3,           // third quartile
          actualMax,    // actual maximum
          rawMin,       // raw minimum
          rawMax        // raw maximum
        ]);

        // Outliers
        const outlierPoints = values.filter(value => value < lowerFence || value > upperFence).map(value => {
          return [categories.indexOf(category), value]; // Use category index as the x-coordinate
        });
        outliersData.push(...outlierPoints);
      });

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            if (params.componentType === 'series' && params.seriesType === 'scatter') {
              return `Category: ${categories[params.data[0]]}<br/>Outlier: ${params.data[1]}`;
            } else if (params.componentType === 'series' && params.seriesType === 'boxplot') {
              const data = tooltipData[params.dataIndex];
              return `
                    Category: ${data[0]}<br/>
                    Min (Actual): ${data[1]}<br/>
                    Q1: ${data[2]}<br/>
                    Median: ${data[3]}<br/>
                    Q3: ${data[4]}<br/>
                    Max (Actual): ${data[5]}<br/>
                    Min (Raw): ${data[6]}<br/>
                    Max (Raw): ${data[7]}
                `;
            }
          }
        },
        xAxis: {
          type: 'category',
          data: categories
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Bulk Expression',
            type: 'boxplot',
            data: boxData,
            itemStyle: {
              color: (params) => {
                // Use params.name to get the actual category and find its index in the categories array
                const categoryIndex = categories.indexOf(params.name);
                return boxColors[categoryIndex % boxColors.length]; // Alternate colors based on index
              },
              borderColor: '#000',
              borderWidth: 2
            },
            emphasis: {
              itemStyle: {
                borderColor: '#000',
                borderWidth: 3,
                shadowBlur: 5,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            boxWidth: ['15%', '20%'],
          },
          {
            name: 'Outliers',
            type: 'scatter',
            data: outliersData,
            itemStyle: {
              color: 'black'
            }
          }
        ]
      };
      chart.setOption(option);
    },
    disposeCharts() {
      if (this.bulkBoxplotChart) {
        this.bulkBoxplotChart.dispose();
        this.bulkBoxplotChart = null;
      }
    },
  }
};
</script>