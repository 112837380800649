<template>
  <nav class="panel">
    <ul class="panel-list">
      <li v-for="(item, index) in menuItems" :key="index" class="panel-item" :class="{ active: isActive(item.to) }"
        @click="setActivePanel(index)">
        <router-link :to="item.to" class="panel-link">
          <span class="panel-text">{{ item.text }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavPanel",
  data() {
    return {
      activePanelIndex: 0,
      menuItems: [
        { to: "/analyze/celltypeumap", text: "Cell type UMAP" },
        { to: "/analyze/celltypeabundance", text: "Cell type abundance" },
        { to: "/analyze/markerprofiles", text: "Marker profiles" },
        { to: "/analyze/signaturenetwork", text: "NMF signature network" },
        { to: "/analyze/cellstatedetail", text: "Cell state detail" },
        { to: "/analyze/cellstatescoring", text: "Cell state scoring" },
        { to: "/analyze/clinicalapplications", text: "Clinical applications" },
        { to: "/analyze/scexpression", text: "scRNA-seq expression" },
        { to: "/analyze/bulkexpression", text: "Bulk expression"},
        { to: "/analyze/stdata", text: "ST data"},
      ],
    };
  },
  methods: {
    setActivePanel(index) {
      this.activePanelIndex = index;
    },
    isActive(path) {
      return this.$route.path === path;
    },
  },
  watch: {
    $route(to) {
      this.activePanelIndex = this.menuItems.findIndex((item) => item.to === to.path);
    },
  },
};
</script>

<style>
.panel {
  height: 60px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-list {
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.panel-item {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  border: 2px solid #ccc;
}

/* 3rd and 7th panel item */
.panel-item:nth-child(3),
.panel-item:nth-child(7) {
  margin-right: 10px;
}

.panel-item.active {
  background-color: white;
  border-bottom: none;
}

.panel-item.active .panel-link,
.panel-item:active .panel-text {
  color: black;
}

.panel-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.panel-text {
  font-size: 20px;
}
</style>