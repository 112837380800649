<template>
  <NavPanel />
  <div class="base-page">
    <StateText />
    <h2>Clinical applications</h2>
    <p>Validation of cell state gene signatures across manual curated longitudinal, cross-sectional, and TCGA-derived
      survival datasets.</p>
    <div class="parent-container">
      <div class="child-container">
        <div class="left-select-wrapper">
          <div class="select-label">Select cell state module</div>
          <select v-model="selectedFile" @change="fetchExpressions" v-if="files.length" class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="file in files" :key="file">{{ file }}</option>
          </select>
        </div>
        <div class="middle-select-wrapper">
          <div class="select-label">Select longitudinal dataset</div>
          <select v-model="selectedLongDataset" @change="fetchFiles" v-if="longDatasets.length" class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="longDataset in longDatasets" :key="longDataset">{{ longDataset }}</option>
          </select>
        </div>
        <div ref="longBoxplot" class="chart"></div>
        <div class="title">Cell State Scoring across {{ selectedLongDataset }} </div>
      </div>
      <div class="child-container">
        <div class="middle-select-wrapper">
          <div class="select-label">Select cross-sectional dataset</div>
          <select v-model="selectedCrossDataset" @change="fetchFiles" v-if="crossDatasets.length"
            class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="crossDataset in crossDatasets" :key="crossDataset">{{ crossDataset }}</option>
          </select>
        </div>
        <div ref="crossBoxplot" class="chart"></div>
        <div class="title">Cell State Scoring across {{ selectedCrossDataset }} </div>
      </div>
      <div class="child-container">
        <div class="select-wrapper-container">
          <div class="middle-select-wrapper">
            <div class="select-label">Select TGCA cancer type</div>
            <select v-model="selectedSurvivalDataset" @change="fetchSurvivalImage" class="dropdown-select">
              <option disabled value="">Select</option>
              <option v-for="survivalDataset in survivalDatasets" :key="survivalDataset">{{ survivalDataset }}</option>
            </select>
          </div>
          <div class="middle-select-wrapper">
            <div class="select-label">Select module</div>
            <select v-model="selectedModuleIndex" @change="fetchSurvivalImage" class="dropdown-select">
              <option disabled value="">Select</option>
              <option v-for="moduleIndex in 19" :key="moduleIndex">{{ moduleIndex }}</option>
            </select>
          </div>
        </div>
        <img :src="imageUrl" ref="survivalImage" alt="Survival Image" class="chart" v-if="imageUrl"/>
        <div class="title">Cell State Scoring survival across {{ selectedSurvivalDataset }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import NavPanel from "@/components/NavPanel.vue";
import StateText from '@/components/StateText.vue';
import commonMixin from '@/mixins/commonMixin';
import 'vue3-select/dist/vue3-select.css';

export default {
  components: { NavPanel, StateText },
  mixins: [commonMixin],
  data() {
    return {
      signatureData: [],
      longDatasets: [],
      selectedLongDataset: null,
      files: [],
      selectedFile: null,
      crossDatasets: [],
      selectedCrossDataset: null,
    };
  },
  mounted() {
    this.selectedSurvivalDataset = 'BLCA',
      this.selectedModuleIndex = 1,
      this.fetchClinicalDatasets();
    this.renderBulkBoxplot({}, echarts.init(this.$refs.longBoxplot));
    this.renderBulkBoxplot({}, echarts.init(this.$refs.crossBoxplot));
    this.fetchSurvivalImage();
    this.fetchSurvivalDatasets();
  },
  methods: {
    async fetchClinicalDatasets() {
      try {
        const longResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
          params: {
            type: 'long'
          }
        });
        this.longDatasets = longResponse.data.bulkDatasets;
        this.selectedLongDataset = this.longDatasets[0];
        const crossResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
          params: {
            type: 'cross'
          }
        });
        this.crossDatasets = crossResponse.data.bulkDatasets;
        this.selectedCrossDataset = this.crossDatasets[2];
        this.fetchFiles();
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    async fetchFiles() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkfiles`, {
          params: {
            type: 'long',
            bulkDataset: this.selectedLongDataset
          }
        });
        this.selectedFile = '';
        this.files = response.data.bulkFiles;
        this.selectedFile = this.files[0];
        this.fetchExpressions();
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    },
    async fetchLongExpressions() {
      try {
        const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
          params: {
            type: 'long',
            bulkDataset: this.selectedLongDataset,
            bulkFile: this.selectedFile
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const dataByCategory = {};

        rows.forEach(row => {
          const [value, category] = row.split('\t');
          if (!dataByCategory[category]) {
            dataByCategory[category] = [];
          }
          dataByCategory[category].push(parseFloat(value));
        });
        const chart = echarts.init(this.$refs.longBoxplot);
        this.renderBulkBoxplot(dataByCategory, chart);
      } catch (error) {
        console.error('Error fetching programs:', error);
      }
    },
    async fetchCrossExpressions() {
      try {
        const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
          params: {
            type: 'cross',
            bulkDataset: this.selectedCrossDataset,
            bulkFile: this.selectedFile
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const dataByCategory = {};

        rows.forEach(row => {
          const [value, category] = row.split('\t');
          if (!dataByCategory[category]) {
            dataByCategory[category] = [];
          }
          dataByCategory[category].push(parseFloat(value));
        });
        const chart = echarts.init(this.$refs.crossBoxplot);
        this.renderBulkBoxplot(dataByCategory, chart);
      } catch (error) {
        console.error('Error fetching programs:', error);
      }
    },
    async fetchExpressions() {
      this.fetchLongExpressions();
      this.fetchCrossExpressions();
    },
    renderBulkBoxplot(dataByCategory, chart) {
      const categories = Object.keys(dataByCategory);

      const boxData = [];
      const tooltipData = [];
      const outliersData = [];

      categories.forEach((category, index) => {
        const values = dataByCategory[category];
        values.sort((a, b) => a - b);
        const rawMin = values[0];
        const rawMax = values[values.length - 1];
        const q1 = values[Math.floor(values.length / 4)];
        const median = values[Math.floor(values.length / 2)];
        const q3 = values[Math.floor(values.length * 3 / 4)];
        const iqr = q3 - q1;
        const lowerFence = q1 - 1.5 * iqr;
        const upperFence = q3 + 1.5 * iqr;

        const actualMin = values.find(v => v >= lowerFence) || rawMin;
        const actualMax = values.reverse().find(v => v <= upperFence) || rawMax;

        boxData.push([actualMin, q1, median, q3, actualMax]);

        tooltipData.push([
          index,
          actualMin,
          q1,
          median,
          q3,
          actualMax,
          rawMin,
          rawMax
        ]);

        const outlierPoints = values.filter(value => value < lowerFence || value > upperFence).map(value => {
          return [index, value];
        });
        outliersData.push(...outlierPoints);
      });

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            if (params.componentType === 'series' && params.seriesType === 'scatter') {
              return `Category: ${categories[params.data[0]]}<br/>Outlier: ${params.data[1]}`;
            } else if (params.componentType === 'series' && params.seriesType === 'boxplot') {
              const data = tooltipData[params.dataIndex];
              return `
                        Category: ${categories[data[0]]}<br/>
                        Min (Actual): ${data[1]}<br/>
                        Q1: ${data[2]}<br/>
                        Median: ${data[3]}<br/>
                        Q3: ${data[4]}<br/>
                        Max (Actual): ${data[5]}<br/>
                        Min (Raw): ${data[6]}<br/>
                        Max (Raw): ${data[7]}
                    `;
            }
          }
        },
        xAxis: {
          type: 'category',
          data: categories
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Bulk Expression',
            type: 'boxplot',
            data: boxData,
            itemStyle: {
              borderWidth: 2
            },
            boxWidth: ['15%', '30%'],
            emphasis: {
              itemStyle: {
                borderColor: '#c23531',
                borderWidth: 3,
                shadowBlur: 5,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          },
          {
            name: 'Outliers',
            type: 'scatter',
            data: outliersData
          }
        ]
      };
      chart.setOption(option);
    },
    async fetchSurvivalDatasets() {
      try {
        if (this.imageUrl) {
          URL.revokeObjectURL(this.imageUrl);
          this.imageUrl = null;
        }
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/survivaldatasets`);
        this.survivalDatasets = response.data.survivalDatasets;
        if (!this.survivalDatasets.includes(this.selectedSurvivalDataset)) {
          this.selectedSurvivalDataset = this.survivalDatasets[0];
        }
      } catch (error) {
        console.error('Error fetching survival datasets:', error);
      }
    },
    async fetchSurvivalImage() {
      try {
        const imageResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/survivalimage`, {
          params: {
            survivalDataset: this.selectedSurvivalDataset,
            moduleIndex: this.selectedModuleIndex
          },
          responseType: 'arraybuffer'
        });
        const blob = new Blob([imageResponse.data], { type: 'image/png' });
        this.imageUrl = URL.createObjectURL(blob);
        this.$refs.survivalImage.src = this.imageUrl;
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    },
  }
};
</script>