<template>
  <NavPanel />
  <div class="base-page">
    <AbundanceText />
    <h2>Cell type abundance</h2>
    <p>The quantitative comparison of cell abundance (ratio, and Ro/e) between premalignant lesions and normal
      controls enrolled in the datasets.</p>
    <div class="parent-container">
      <div class="child-container">
        <div class="left-select-wrapper">
          <div class="select-label">Select cancer type</div>
          <select v-model="selectedSCDataset" @change="handleSCDatasetChange" v-if="scDatasets.length"
            class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="scDataset in scDatasets" :key="scDataset">{{ scDataset }}</option>
          </select>
        </div>
        <div ref="barChart" class="chart"></div>
        <div class="title">Cellular distribution across lesions in {{ selectedSCDataset }}</div>
      </div>
      <div class="child-container">
        <div class="title">Cell Type Abundance (Ro/e) across Lesions</div>
        <table v-if="scAbundanceTable.headers.length">
          <thead>
            <tr>
              <th v-for="header in scAbundanceTable.headers" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in scAbundanceTable.rows" :key="rowIndex">
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import seedrandom from 'seedrandom';
import NavPanel from "@/components/NavPanel.vue";
import AbundanceText from '@/components/AbundanceText.vue';
import commonMixin from '@/mixins/commonMixin';

export default {
  components: { NavPanel, AbundanceText },
  mixins: [commonMixin],
  data() {
    return {
      barChart: null,
      scDatasets: [],
      selectedSCDataset: '',
      lesionStats: {},
      scAbundanceTable: {
        headers: [],
        rows: []
      },
      scatterData: [],
      selectedAttributes: [null, null],
    };
  },
  mounted() {
    this.fetchSCDatasets();
  },
  methods: {
    async fetchSCDatasets() {
      try {
        this.selectedSCDataset = 'BRCA'; // prefetch BRCA
        this.handleSCDatasetChange();
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
        this.scDatasets = response.data.scDatasets;
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    async fetchLesions() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/lesions`, {
          params: {
            tableName: `${this.selectedSCDataset.toLowerCase()}s`
          }
        });
        this.lesionStats = response.data.lesionStats;
        this.renderBarChart();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async fetchSCScatter(index) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scscatter`, {
          params: {
            tableName: `${this.selectedSCDataset.toLowerCase()}s`,
            attribute: this.selectedAttributes[index]
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const headers = rows[0].split('\t');
        this.scatterData = rows.slice(1).map(row => {
          const values = row.split('\t');
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
        });
        this.renderSCScatter(index);
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async fetchSCAbundanceData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scabundancedata`, {
          params: {
            scAbundanceDataset: this.selectedSCDataset
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const lines = decompressedData.trim().split('\n');
        let headers = lines[0].split('\t');
        const rows = lines.slice(1).map(line => {
          const values = line.split('\t');
          values[1] = Number(values[1]).toFixed(3);
          values[2] = Number(values[2]).toFixed(3);
          return values;
        });
        if (headers.length + 1 === rows[0].length) {
          headers = ['', ...headers];
        }

        this.scAbundanceTable = { headers, rows };
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    renderBarChart() {
      if (!this.$refs.barChart) return;

      const seriesData = this.lesionStats;

      let xAxisData = Object.keys(seriesData);
      let legendDataSet = new Set();

      xAxisData.forEach(diseaseName => {
        Object.keys(seriesData[diseaseName]).forEach(cellType => {
          legendDataSet.add(cellType);
        });
      });

      let legendData = Array.from(legendDataSet);

      let series = legendData.map(cellTypeItem => {
        return {
          name: cellTypeItem,
          type: 'bar',
          stack: 'cellType',
          barWidth: '20%',
          data: xAxisData.map(diseaseName => {
            let total = Object.values(seriesData[diseaseName]).reduce((sum, val) => sum + val, 0);
            let composition = seriesData[diseaseName][cellTypeItem] || 0;
            return (composition / total) * 100;
          })
        };
      });

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let tooltipText = params[0].name + '<br/>';
            params.forEach(item => {
              tooltipText += item.marker + ' ' + item.seriesName + ': ' + item.value.toFixed(4) + '%<br/>';
            });
            return tooltipText;
          }
        },
        legend: {
          data: legendData,
          bottom: 'bottom'
        },
        grid: {
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              interval: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: series
      };

      this.$nextTick(() => {
        if (this.barChart) {
          this.barChart.dispose();
        }
        if (this.$refs.barChart) {
          this.barChart = echarts.init(this.$refs.barChart);
          this.barChart.setOption(option);
        }
      });
    },
    renderSCScatter(index) {
      let chartObj = this.$refs[`scatterPlot${index}`];
      console.log(chartObj);
      if (!chartObj) return;
      const rng = seedrandom('fixed-seed');
      let data = this.scatterData.slice();
      if (data.length > 5000) {
        for (let i = data.length - 1; i > 0; i--) {
          const j = Math.floor(rng() * (i + 1));
          [data[i], data[j]] = [data[j], data[i]];
        }
        data = data.slice(0, 5000);
      }
      if (!chartObj) return;
      this.scatterCharts[index] = echarts.init(chartObj);
      const categories = [...new Set(data.map(item => item['colortype']))];
      const colors = Array.from({ length: categories.length }, (_, i) =>
        echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
      );
      const colorMap = categories.reduce((acc, category, index) => {
        acc[category] = colors[index];
        return acc;
      }, {});
      const containerWidth = chartObj.clientWidth;
      const containerHeight = chartObj.clientHeight;
      const size = Math.min(containerWidth, containerHeight) - 75;
      const left = (containerWidth - size) / 2;

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.marker} ${params.data.name}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
          }
        },
        grid: {
          left: `${left}px`,
          right: `${left}px`,
          bottom: '10%',
          containLabel: true,
          width: size,
          height: size,
        },
        xAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        series: [
          {
            name: 'Scatter',
            type: 'scatter',
            symbolSize: 4,
            data: data.map(item => ({
              value: [
                parseFloat(item['umap_1']),
                parseFloat(item['umap_2'])
              ],
              name: item['colortype'],
              itemStyle: {
                color: colorMap[item['colortype']]
              }
            }))
          }
        ],
      };

      this.scatterCharts[index].setOption(option);
      this.createLegend(index, colorMap);
    },

    createLegend(index, colorMap) {
      const legendContainer = this.$refs[`legendContainer${index}`];
      legendContainer.innerHTML = '';

      Object.keys(colorMap).forEach(category => {
        const legendItem = document.createElement('span');
        legendItem.style.display = 'flex';
        legendItem.style.alignItems = 'center';
        legendItem.style.marginBottom = '5px';
        legendItem.style.marginRight = '15px';

        const colorBox = document.createElement('span');
        colorBox.style.width = '25px';
        colorBox.style.height = '14.4px';
        colorBox.style.backgroundColor = colorMap[category];
        colorBox.style.marginRight = '10px';
        colorBox.style.borderRadius = '3px';

        const labelText = document.createElement('span');
        labelText.textContent = category;
        labelText.style.fontSize = '12px';
        labelText.style.fontFamily = 'Helvetica, sans-serif';
        labelText.style.fontWeight = 'semibold';

        legendItem.appendChild(colorBox);
        legendItem.appendChild(labelText);
        legendContainer.appendChild(legendItem);
      });
    },
    async fetchSCMarkerData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scmarkerdata`, {
          params: {
            scMarkerDataset: this.selectedSCDataset
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const lines = decompressedData.trim().split('\n');
        let headers = lines[0].split('\t');
        headers = [headers[3], headers[2], headers[0], headers[1], headers[4]];
        const rows = lines.slice(1).map(line => {
          let values = line.split('\t');
          values = [values[3], values[2], values[0], values[1], values[4]];
          for (let i = 2; i < 5; i++) {
            values[i] = Number(values[i]).toFixed(3);
          }
          return values;
        });

        this.scMarkerTable = { headers, rows };
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async handleSCDatasetChange() {
      await this.fetchLesions();
      await this.fetchSCAbundanceData();
    },
  }
};
</script>