<template>
    <h1>
      <p>Cell state analysis</p>
    </h1>
    <p>In PreAtlas, we focus on single-cell RNA-seq data of epithelial cells in dysplasia or similar premalignant
      stages (with a risk of becoming tumor precursors). Through a workflow involving <strong> matrix
        factorization (NMF) and hierarchical clustering</strong>, we extract factor clusters representing
      distinct cell states of premalignant epithelial cells at this stage. These clusters are mapped to specific
      gene signatures and functionally annotated into <strong>13 premalignant modules</strong>.</p>
</template>

<script>
export default {
  name: "StateText"
}
</script>

<style>
.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 5%;
  z-index: 1;
}

.boxplot-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.boxplot {
  width: 48%;
  position: relative;
}

.chart {
  margin-top: 50px;
  width: 100%;
  height: 600px;
}

.boxplot-dropdown {
  position: absolute;
  top: 10px;
  left: 10px;
}

.dropdown-warning {
  color: red;
}
</style>