<template>
  <header>
    <div class="header-wrapper">
      <img src="../assets/images/banner.png" alt="header image" class="header_image">
      <div class="header-titles">
        <h1 class="title-preatlas title-stress-color">PreAtlas</h1>
        <div class="title-divider"></div>
        <h2 class="title-subtitle"><span class="title-stress-color">Pre</span>malignant disease <span class="title-stress-color">Atlas</span></h2>
      </div>
    </div>
    <nav class="navbar">
      <ul class="nav-list">
        <li v-for="(item, index) in menuItems" :key="index" class="nav-item" :class="{ active: activeNavIndex === index }"
          @click="setActiveNav(index)">
          <router-link :to="item.to" class="nav-link">
            <font-awesome-icon :icon="item.icon" alt="Icon" class="nav-icon" />
            <span class="nav-text">{{ item.text }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderNavbar',
  data() {
    return {
      activeNavIndex: 0,
      menuItems: [
        { to: '/', icon: ['fas', 'home'], text: 'Home' },
        { to: '/analyze/celltypeumap', icon: ['fas', 'chart-line'], text: 'Analysis' },
        { to: '/doc', icon: ['fas', 'book'], text: 'Documentation' },
        { to: '/download', icon: ['fas', 'download'], text: 'Download' },
        { to: '/contact', icon: ['fas', 'envelope'], text: 'Help & Contact' }
      ]
    };
  },
  methods: {
    setActiveNav(index) {
      this.activeNavIndex = index;
    }
  }
};
</script>

<style scoped>
.header_image {
  width: 100%;
  height: 250px;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: top;
}

.header-wrapper {
  position: relative;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.header-titles {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.title-preatlas {
  font-size: 48px;
  margin: 0;
}

.title-stress-color {
  color: lightgreen;
}

.title-divider {
  width: 80%;
  height: 2px;
  background-color: white;
  margin: 10px auto;
}

.title-subtitle {
  font-size: 36px;
  color: #A6A6A6;
  margin: 0;
}

.navbar {
  width: 80%;
  height: 60px;
  margin: 20px auto;
  background-color: #065398;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  height: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.nav-item {
  padding: 0 30px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.nav-item.active {
  background-color: #C4E1FF;
}

.nav-item:hover {
  background-color: #E1F0FF;
}

.nav-item.active .nav-link,
.nav-item:active .nav-text {
  color: black;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.nav-text {
  font-size: 20px
}
</style>