<template>
  <NavPanel />
  <div class="base-page">
    <AbundanceText />
    <h2>Marker profiles</h2>
    <p>Differentially expressed gene markers derived by FindAllmarkers of Seurat.</p>
    <div class="parent-container">
      <div class="child-container">
        <div class="left-select-wrapper">
          <div class="select-label">Select cancer type</div>
          <select v-model="selectedSCDataset" @change="fetchSCMarkerData" v-if="scDatasets.length"
            class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="scDataset in scDatasets" :key="scDataset">{{ scDataset }}</option>
          </select>
        </div>
        <div class="title">Differentially Expressed Genes across Cell Types</div>
        <table v-if="scMarkerTable.headers.length">
          <thead>
            <tr>
              <th v-for="(header, index) in scMarkerTable.headers" :key="header" @click="sortTable(index)">
                <span class="header-content">
                  {{ header }}
                  <span class="sort-icons">
                    <div class="triangle-up"
                      :class="{ active: sortColumn === index && sortDirection === 'asc', inactive: sortColumn !== index }">
                    </div>
                    <div class="triangle-down"
                      :class="{ active: sortColumn === index && sortDirection === 'desc', inactive: sortColumn !== index }">
                    </div>
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in paginatedRows" :key="rowIndex">
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination" v-if="scMarkerTable.headers.length">
          <button @click="goToPage(1)" :disabled="currentPage === 1">First</button>
          <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>

          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
            :class="{ active: currentPage === page }">
            {{ page }}
          </button>

          <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
          <button @click="goToPage(totalPages)" :disabled="currentPage === totalPages">Last({{ totalPages }})</button>
          <input type="number" v-model.number="inputPage" @keyup.enter="goToInputPage" min="1" :max="totalPages" />
          <button @click="goToInputPage">Go</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import pako from 'pako';
import NavPanel from "@/components/NavPanel.vue";
import AbundanceText from '@/components/AbundanceText.vue';
import commonMixin from '@/mixins/commonMixin';

export default {
  components: { NavPanel, AbundanceText },
  mixins: [commonMixin],
  data() {
    return {
      scDatasets: [],
      selectedSCDataset: '',
      scMarkerTable: {
        headers: [],
        rows: []
      },
      currentPage: 1,
      rowsPerPage: 15,
      nearbyPagesCount: 2,
      sortColumn: 0,
      sortDirection: 'asc',
      inputPage: 1,
    };
  },
  mounted() {
    this.fetchSCDatasets();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.sortedRows(this.scMarkerTable.rows).length / this.rowsPerPage);
    },
    paginatedRows() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.sortedRows(this.scMarkerTable.rows).slice(start, end);
    },
    visiblePages() {
      let start = Math.max(1, this.currentPage - this.nearbyPagesCount);
      let end = Math.min(this.totalPages, this.currentPage + this.nearbyPagesCount);

      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    }
  },
  methods: {
    async fetchSCDatasets() {
      try {
        this.selectedSCDataset = 'BRCA'; // prefetch BRCA
        this.fetchSCMarkerData();
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
        this.scDatasets = response.data.scDatasets;
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    async fetchSCScatter(index) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scscatter`, {
          params: {
            tableName: `${this.selectedSCDataset.toLowerCase()}s`,
            attribute: this.selectedAttributes[index]
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const headers = rows[0].split('\t');
        this.scatterData = rows.slice(1).map(row => {
          const values = row.split('\t');
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
        });
        this.renderSCScatter(index);
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async fetchSCAbundanceData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scabundancedata`, {
          params: {
            scAbundanceDataset: this.selectedSCDataset
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const lines = decompressedData.trim().split('\n');
        let headers = lines[0].split('\t');
        const rows = lines.slice(1).map(line => {
          const values = line.split('\t');
          values[1] = Number(values[1]).toFixed(3);
          values[2] = Number(values[2]).toFixed(3);
          return values;
        });
        if (headers.length + 1 === rows[0].length) {
          headers = ['', ...headers];
        }

        this.scAbundanceTable = { headers, rows };
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async fetchSCMarkerData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scmarkerdata`, {
          params: {
            scMarkerDataset: this.selectedSCDataset
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const lines = decompressedData.trim().split('\n');
        let headers = lines[0].split('\t');
        headers = [headers[3], headers[2], headers[0], headers[1], headers[4]];
        const rows = lines.slice(1).map(line => {
          let values = line.split('\t');
          values = [values[3], values[2], values[0], values[1], values[4]];
          for (let i = 2; i < 5; i++) {
            values[i] = Number(values[i]).toFixed(3);
          }
          return values;
        });

        this.scMarkerTable = { headers, rows };
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    }
  }
};
</script>