<template>
  <NavPanel />
  <div class="base-page">
    <AbundanceText />
    <h2>Cell type UMAP</h2>
    <p>Cell type compositions, and basic clical information for the samples.</p>
    <div class="parent-container">
      <div class="child-container">
        <div class="left-select-wrapper">
          <div class="select-label">Select cancer type</div>
          <select v-model="selectedSCDataset" @change="handleSCDatasetChange" v-if="scDatasets.length"
            class="dropdown-select">
            <option disabled value="">Select</option>
            <option v-for="scDataset in scDatasets" :key="scDataset">{{ scDataset }}</option>
          </select>
        </div>
        <div class="middle-select-wrapper">
          <div class="select-label">Select sample attribute</div>
          <select v-model="selectedAttributes[0]" @change="fetchSCScatter0" class="dropdown-select">
            <option disabled value="">Select Attribute</option>
            <option v-for="scAttribute in scAttributes" :key="scAttribute">{{ scAttribute }}</option>
          </select>
        </div>
        <div ref="scatterPlot0" class="chart"></div>
        <div ref="legendContainer0" class="legend-container"></div>
        <div class="title">UMAP across {{ selectedAttributes[0] }} in {{ selectedSCDataset }}</div>
      </div>
      <div class="child-container">
        <div class="middle-select-wrapper">
          <div class="select-label">Select sample attribute</div>
          <select v-model="selectedAttributes[1]" @change="fetchSCScatter1" class="dropdown-select">
            <option disabled value="">Select Attribute</option>
            <option v-for="scAttribute in scAttributes" :key="scAttribute">{{ scAttribute }}</option>
          </select>
        </div>
        <div ref="scatterPlot1" class="chart"></div>
        <div ref="legendContainer1" class="legend-container"></div>
        <div class="title">UMAP across {{ selectedAttributes[1] }} in {{ selectedSCDataset }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import pako from 'pako';
import seedrandom from 'seedrandom';
import NavPanel from "@/components/NavPanel.vue";
import AbundanceText from '@/components/AbundanceText.vue';
import commonMixin from '@/mixins/commonMixin';

export default {
  components: { NavPanel, AbundanceText },
  mixins: [commonMixin],
  data() {
    return {
      scatterCharts: [null, null],
      scDatasets: [],
      selectedSCDataset: '',
      scatterData: [],
      selectedAttributes: [null, null],
      scAttributes: ['presig_cell_type', 'presig_dataset_id', 'presig_lesion_orig', 'presig_lesion_revised', 'presig_cancer_source'],
    };
  },
  mounted() {
    this.fetchSCDatasets();
  },
  methods: {
    async fetchSCDatasets() {
      try {
        this.selectedSCDataset = 'BRCA'; // prefetch BRCA
        this.handleSCDatasetChange();
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
        this.scDatasets = response.data.scDatasets;
      } catch (error) {
        console.error('Error fetching cancer types:', error);
      }
    },
    async fetchSCScatter(index) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scscatter`, {
          params: {
            tableName: `${this.selectedSCDataset.toLowerCase()}s`,
            attribute: this.selectedAttributes[index]
          },
          responseType: 'arraybuffer'
        });

        const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
        const rows = decompressedData.trim().split('\n');
        const headers = rows[0].split('\t');
        this.scatterData = rows.slice(1).map(row => {
          const values = row.split('\t');
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
        });
        this.renderSCScatter(index);
      } catch (error) {
        console.error('Error fetching scatter data:', error);
      }
    },
    async fetchSCScatter0() {
      await this.fetchSCScatter(0);
    },
    async fetchSCScatter1() {
      await this.fetchSCScatter(1);
    },
    renderSCScatter(index) {
      let chartObj = this.$refs[`scatterPlot${index}`];
      console.log(chartObj);
      if (!chartObj) return;
      const rng = seedrandom('fixed-seed');
      let data = this.scatterData.slice();
      if (data.length > 5000) {
        for (let i = data.length - 1; i > 0; i--) {
          const j = Math.floor(rng() * (i + 1));
          [data[i], data[j]] = [data[j], data[i]];
        }
        data = data.slice(0, 5000);
      }
      if (!chartObj) return;
      this.scatterCharts[index] = echarts.init(chartObj);
      const categories = [...new Set(data.map(item => item['colortype']))];
      const colors = Array.from({ length: categories.length }, (_, i) =>
        echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
      );
      const colorMap = categories.reduce((acc, category, index) => {
        acc[category] = colors[index];
        return acc;
      }, {});
      const containerWidth = chartObj.clientWidth;
      const containerHeight = chartObj.clientHeight;
      const size = Math.min(containerWidth, containerHeight) - 75;
      const left = (containerWidth - size) / 2;

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.marker} ${params.data.name}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
          }
        },
        grid: {
          left: `${left}px`,
          right: `${left}px`,
          bottom: '10%',
          containLabel: true,
          width: size,
          height: size,
        },
        xAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false }
        },
        series: [
          {
            name: 'Scatter',
            type: 'scatter',
            symbolSize: 4,
            data: data.map(item => ({
              value: [
                parseFloat(item['umap_1']),
                parseFloat(item['umap_2'])
              ],
              name: item['colortype'],
              itemStyle: {
                color: colorMap[item['colortype']]
              }
            }))
          }
        ],
      };
      this.scatterCharts[index].setOption(option);
      this.createLegend(index, colorMap);
    },
    createLegend(index, colorMap) {
      const legendContainer = this.$refs[`legendContainer${index}`];
      legendContainer.innerHTML = '';

      Object.keys(colorMap).forEach(category => {
        const legendItem = document.createElement('span');
        legendItem.style.display = 'flex';
        legendItem.style.alignItems = 'center';
        legendItem.style.marginBottom = '5px';
        legendItem.style.marginRight = '15px';

        const colorBox = document.createElement('span');
        colorBox.style.width = '25px';
        colorBox.style.height = '14.4px';
        colorBox.style.backgroundColor = colorMap[category];
        colorBox.style.marginRight = '10px';
        colorBox.style.borderRadius = '3px';

        const labelText = document.createElement('span');
        labelText.textContent = category;
        labelText.style.fontSize = '12px';
        labelText.style.fontFamily = 'Helvetica, sans-serif';
        labelText.style.fontWeight = 'semibold';

        legendItem.appendChild(colorBox);
        legendItem.appendChild(labelText);
        legendContainer.appendChild(legendItem);
      });
    },
    async handleSCDatasetChange() {
      this.selectedAttributes[0] = this.scAttributes[0]; // presig_cell_type
      this.selectedAttributes[1] = this.scAttributes[3]; // presig_lesion_revised
      this.fetchSCScatter(0);
      this.fetchSCScatter(1);
    },
  }
};
</script>