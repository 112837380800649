export default {
  methods: {
    setActivePanel(index) {
      this.activePanelIndex = index;
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    goToInputPage() {
      if (this.inputPage >= 1 && this.inputPage <= this.totalPages) {
        this.goToPage(this.inputPage);
      }
    },
    sortTable(columnIndex) {
      if (this.sortColumn === columnIndex) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = columnIndex;
        this.sortDirection = 'asc';
      }
      this.currentPage = 1;  // Reset to first page after sorting
    },
    sortedRows(rows) {
      if (this.sortColumn === null) return rows;
  
      const sorted = [...rows];
      return sorted.sort((a, b) => {
        const valueA = a[this.sortColumn];
        const valueB = b[this.sortColumn];
  
        const isNumber = !isNaN(parseFloat(valueA)) && isFinite(valueA);
  
        if (isNumber) {
          return this.sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
        } else {
          return this.sortDirection === 'asc'
            ? String(valueA).localeCompare(String(valueB))
            : String(valueB).localeCompare(String(valueA));
        }
      });
    }
  },
};
