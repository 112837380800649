<template>
  <div class="doc-page">
    <div class="doc-text">
      <h1>Introduction of premalignant lesions</h1>
      <p>
        In most organs of human, there lays a step-by-step cascade for normal healthy tissue to gain malignancy. Solid tumors mostly originated from malignant epithelial cells, their carcinogenesis cascades can be depicted as a step-by-step procedure of (1) normal epithelial cells, (2) hyperplasia, (3) metaplasia, (4) low- and high-grade dysplasia, (5) carcinoma in-situ and (6) invasive cancer. Noticeably, some types of cancer have premalignant lesions, or precancerous disease, clinically diagnosed which correspond, to a greater or lesser extent, to certain stages in the carcinogenesis procedure in terms of pathology and cytology. Additionally, some types of cancer are poorly diagnosed at premalignant or early stages, while patients observed with certain lesions are at high risk of cancer progression. Thus, these lesions and disease are noticed as precancerous lesions, and are considered as priorities in early cancer prediction and prevention in our research. Here we list certain established premalignant lesions identified clinically of several cancer types in two major groups: squamous cell carcinoma and adenocarcinoma.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocPage',
};
</script>

<style scoped>
.doc-page {
  display: flex;
  justify-content: center;
  padding: 20px;

  .doc-text {
    text-align: left;
    font-size: 30px;
    line-height: 1.6;
    max-width: 80%;
  }
}
</style>
